<script setup lang='ts'>
import { ref } from 'vue';

import type { TranslatedField } from '@/types/mode-list-filters.js';

import useLocale from '@/composables/useLocale';
import useModeListFilters from '@/composables/useModeListFilters';

type Option = { id: string; name: string; count: number; type: string; translatedName: Array<TranslatedField> };

const props = defineProps<{
  options: Array<Option>;
}>();
const emit = defineEmits(['update:modelValue']);
const { filters } = useModeListFilters();
const { currLocale } = useLocale();
const selectedOptions = ref<Array<Option>>([]);
const debounce = ref();

const savedPlaces = filters.value.selections.find((it: any) => it.filterProperties.type === 'taxonomyPlaces')?.selection ?? [];
if (savedPlaces.length > 0) {
  for (const place of savedPlaces) {
    const matchingOption = props.options.find(it => it.id === place);
    if (matchingOption) {
      const isSelected = selectedOptions.value.find(it => it.id === matchingOption.id);
      if (!isSelected)
        selectedOptions.value.push(matchingOption);
    }
  }
}

function selectOption(option: Option) {
  if (debounce.value) {
    clearTimeout(debounce.value);
  }
  if (selectedOptions.value.some(it => it.name === option.name)) {
    selectedOptions.value = selectedOptions.value.filter(item => item.name !== option.name);
  }
  else {
    selectedOptions.value.push(option);
  }
  debounce.value = setTimeout(() => {
    emit('update:modelValue', selectedOptions.value);
  }, 750);
}
</script>

<template>
  <ul id="options-list" role="listbox" class="options-list">
    <div
      v-for="(option, index) in props.options"
      :key="index"
      class="option-row"
      tabindex="0"
    >
      <li
        role="option"
        tabindex="0"
        class="option"
        :aria-selected="selectedOptions.some(it => it.name === option.name)"
        @click="selectOption(option)"
        @keydown="selectOption(option)"
      >
        <label :id="index.toString()" :for="index.toString()">{{ option.translatedName.find((it: any) => it.lang === currLocale)?.value ?? option.name }}<span style="margin-left:4px;">({{ option.count }})</span></label>
        <input :id="index.toString()" type="checkbox" :name="option.name" :value="option.name" :checked="selectedOptions.some(it => it.name === option.name)" :aria-describedby="index.toString()">
      </li>
    </div>
  </ul>
</template>

<style lang="css" scoped>
ul {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

.options-list {
  list-style-type: none;
  background-color: var(--yb-color-white);
  border-radius: var(--yb-radius-sm);
}

.option-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: var(--yb-radius-sm);
  outline: none;
  padding-block: var(--yb-spacing-xs);
  padding-right: var(--yb-spacing-xs);
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-wrap: nowrap;
  color: var(--yb-color-black);
  cursor: pointer;

  & > * {
    pointer-events: none;
  }

  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    width: 16px;
    aspect-ratio: 1;
    border: 1px solid var(--yb-color-mediumgray);
    border-radius: 2px;
    background-color: var(--yb-color-white);
    margin-inline: var(--yb-spacing-xs);
    accent-color: var(--yb-color-primary);
  }

  input[type='checkbox']:checked {
    background-color: var(--yb-color-primary);
    border-color: var(--yb-color-primary);
    color: var(--yb-color-white);
  }

  input[type='checkbox']:checked::before {
    content: '\2713';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    text-align: center;
    color: var(--yb-color-white);
    font-size: 16px;
  }
}

.option-row:hover,
.option-row:focus,
.option-row:focus-visible {
  background-color: var(--yb-color-lightgray);
}
</style>
