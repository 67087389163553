<script setup lang="ts">
import TheSearch from '@/components/TheSearch.vue';
import { computed, getCurrentInstance, h, onBeforeMount, provide, ref, shallowRef } from 'vue';
import { createI18n, I18nInjectionKey } from 'vue-i18n';

import type { WoodyConfig } from '@/types/mode-list-config';

import useLocale from '@/composables/useLocale';
import useModeListData from '@/composables/useModeListData';
import de from '@/locales/de';
import en from '@/locales/en';
import es from '@/locales/es';
import fr from '@/locales/fr';

const props = defineProps<{ locale?: 'en-GB' | 'fr-FR' | 'es-ES' | 'de-DE'; config: string }>();
const { setupCurrentLocale } = useLocale();
const { setupWoodyConfig } = useModeListData();

const $root = ref<HTMLElement>();
const css = ref('');
const blockCLS = ref(true);
const renderStyle = computed(() => {
  return h('style', css.value);
});

const config = ref<WoodyConfig>();
const localization = shallowRef<string>();

const timeout = 0;
function updateStyles() {
  blockCLS.value = true;
  const selector = process.env.PROD ? '[data-source="myapp"]' : 'style';
  clearTimeout(timeout);
  setTimeout(() => {
    const styles = Array.from(document.querySelectorAll(selector));
    css.value = styles
      .map(it => it.textContent ?? '')
      .join('');
    blockCLS.value = false;
  }, 25);
}

config.value = props.config?.length ? JSON.parse(props.config) : null;
localization.value = config.value?.locale ? config.value.locale : props.locale ? props.locale : 'fr-FR';
setupCurrentLocale(localization.value as string);

const i18n = createI18n({
  legacy: false, // must set to `false` for composition API
  locale: localization.value,
  fallbackLocale: 'fr-FR',
  messages: {
    en,
    fr,
    es,
    de,
  },
});
i18n.install(getCurrentInstance()!.appContext.app);
provide(I18nInjectionKey, i18n);

onBeforeMount(async () => {
  updateStyles();
  const meta = document.createElement('meta');
  const color = getComputedStyle(document.documentElement).getPropertyValue('--yb-theme-color');
  meta.name = 'theme-color';
  meta.content = `rgb(${color})`;
  document.getElementsByTagName('head')[0].appendChild(meta);
  if (config.value) {
    setupWoodyConfig(config.value);
    const modeDetailComponent = document.querySelector('yb-details');
    if (modeDetailComponent)
      modeDetailComponent.setAttribute('prefix-url', config.value?.prefixUrl);
  }
  else {
    throw new Error('Missing config data');
  }
});
</script>

<template>
  <TheSearch v-if="!blockCLS" ref="$root" />
  <renderStyle />
</template>

<style>
@import '@/assets/globals.css';
</style>
