<script setup lang='ts'>
import { CalendarDays, Plus, Search } from 'lucide-vue-next';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useLocale from '@/composables/useLocale';
import useModeListData from '@/composables/useModeListData';
import useModeListFilters from '@/composables/useModeListFilters';
import useResponsive from '@/composables/useResponsive';

import TheCalendar from './mode-list/TheCalendar.vue';
import TheMobileFilters from './mode-list/TheMobileFilters.vue';
import TheThemesDropdown from './mode-list/TheThemesDropdown.vue';

const { t } = useI18n();
const { isMobile } = useResponsive();
const { datesAsStrings, addSearchingFilter, filters, loadFiltersFromSessionStorage, saveFiltersToSessionStorage, setDatesAsStrings } = useModeListFilters();
const { fetchProductsList, fetchInitialProductsList, additionalFetchProductsList, themes, themesRouting } = useModeListData();
const { currLocale } = useLocale();
const openThemesPopover = ref(false);
const openCalendarPopover = ref(false);
const themesPopover = ref();
const calendarPopover = ref();

const currentSearch = computed(() => filters.value.sorts.find(it => it.type === 'search')?.query ?? '');
const activeTheme = computed(() => filters.value.selections.find(it => it.filterProperties.type === 'taxonomyThemes')?.selection?.[0]) ?? undefined;
const activeThemeLabel = computed(() => themes.value.find(it => it.id === activeTheme.value)?.translatedName.find((it: any) => it.lang === currLocale.value)?.value ?? t('search.themes'));

async function handleSearchInput(event: any) {
  addSearchingFilter(event.target.value);
  const defaultRoute = themesRouting.value.find(it => it.taxonomies?.length === 0);
  if (defaultRoute && window.location.href !== defaultRoute.url) {
    window.location.href = defaultRoute.url;
  }
  else {
    const baseFilters = { ...filters.value };
    baseFilters.sorts = baseFilters.sorts.filter((it: any) => it.type !== 'search');
    await additionalFetchProductsList(baseFilters);
    fetchProductsList(filters.value);
  }
}

function toggleThemesPopover() {
  if (openCalendarPopover.value === true) {
    openCalendarPopover.value = false;
    window.removeEventListener('click', handleCalendarOutsideClick);
  }
  openThemesPopover.value = !openThemesPopover.value;
  setTimeout(() => {
    openThemesPopover.value === true ? window.addEventListener('click', handleThemesOutsideClick) : window.removeEventListener('click', handleThemesOutsideClick);
    openThemesPopover.value === true ? window.addEventListener('keydown', handleThemesOutsideClick) : window.removeEventListener('keydown', handleThemesOutsideClick);
  }, 100);
}

function toggleCalendarPopover() {
  if (openThemesPopover.value === true) {
    openThemesPopover.value = false;
    window.removeEventListener('click', handleThemesOutsideClick);
  }
  openCalendarPopover.value = !openCalendarPopover.value;
  setTimeout(() => {
    openCalendarPopover.value === true ? window.addEventListener('click', handleCalendarOutsideClick) : window.removeEventListener('click', handleCalendarOutsideClick);
    openCalendarPopover.value === true ? window.addEventListener('keydown', handleCalendarOutsideClick) : window.removeEventListener('keydown', handleCalendarOutsideClick);
  }, 100);
}

function handleThemesOutsideClick(event: Event) {
  // @ts-expect-error typeof event
  if (event.key === 'ArrowUp' || event.key === 'ArrowDown')
    return;
  const target = event.target as HTMLElement;
  // @ts-expect-error typeof event
  if (!themesPopover.value || !themesPopover.value.contains(target) || event.key === 'Escape') {
    toggleThemesPopover();
  }
};

function handleCalendarOutsideClick(event: Event) {
  const target = event.target as HTMLElement;
  const calendar = document.querySelector('yb-search');
  if (calendar && (target !== calendar)) {
    toggleCalendarPopover();
  }
  // @ts-expect-error typeof event
  if (event.key === 'Escape') {
    toggleCalendarPopover();
  }
};

function deleteTheme() {
  filters.value.metrics = filters.value.metrics.filter(it => it.type !== 'crossedTaxonomyCount');
  filters.value.selections = filters.value.selections.filter(it => it.filterProperties?.type !== 'taxonomyThemes');
  filters.value.selections = filters.value.selections.filter(it => it.filterProperties?.type !== 'taxonomyTypes');

  saveFiltersToSessionStorage();
  const defaultRoute = themesRouting.value.find(it => it.taxonomies?.length === 0);
  if (defaultRoute && window.location.href !== defaultRoute.url) {
    window.location.href = defaultRoute.url;
  }
  else {
    fetchProductsList(filters.value);
  }
}

function deleteDates() {
  const definedDates = filters.value.filters.findIndex(it => it.filterProperties.type === 'date_range');
  if (definedDates > -1) {
    filters.value.filters.splice(definedDates, 1);
  }
  datesAsStrings.value = null;
  saveFiltersToSessionStorage();
  fetchProductsList(filters.value);
}

onMounted(() => {
  // Enable state on pages with mode-details and no list component
  const hasListComponent = document.querySelector('yb-list');
  if (!hasListComponent) {
    loadFiltersFromSessionStorage();
    fetchInitialProductsList(filters.value);
  }

  const savedDates = filters.value.filters.find(it => it.filterProperties.type === 'date_range')?.selection;
  if (savedDates)
    setDatesAsStrings({ start: savedDates.startDate, end: savedDates.endDate });
});
</script>

<template>
  <section v-if="!isMobile" :arial-label="t('search.label')">
    <div class="search-filter">
      <div class="filter-wrapper" tabindex="0">
        <Search :size="18" color="var(--yb-color-black)" aria-hidden="true" />
        <input type="text" class="search_query" :placeholder="t('search.placeholder')" :value="currentSearch" :aria-label="t('search.placeholder')" @change="(v) => handleSearchInput(v)" @keydown.enter="(v) => handleSearchInput(v)">
      </div>
    </div>
    <div class="search-filter">
      <div class="search-buttons">
        <button type="button" class="filter-wrapper" :class="{ opened: openThemesPopover }" tabindex="0" :aria-pressed="openThemesPopover" :aria-label="t('search.togglethemes')" @click="toggleThemesPopover">
          <p :class="{ active: activeTheme }">
            {{ activeThemeLabel }}
          </p>
        </button>
        <button v-if="activeTheme" type="button" class="delete-button" aria-label="Supprimer le theme selectionné" @click="deleteTheme">
          <Plus :size="18" color="var(--yb-color-black)" style="rotate:45deg;" />
        </button>
      </div>
      <div v-if="openThemesPopover" ref="themesPopover" class="popover">
        <TheThemesDropdown />
      </div>
    </div>
    <div class="search-filter calendar">
      <div class="search-buttons">
        <button type="button" class="filter-wrapper" :class="{ opened: openCalendarPopover }" tabindex="0" :aria-pressed="openCalendarPopover" :aria-label="t('search.togglecalendar')" @click="toggleCalendarPopover">
          <CalendarDays :size="18" color="var(--yb-color-black)" aria-hidden="true" />
          <p :class="{ active: datesAsStrings }">
            {{ datesAsStrings ?? t('search.dates') }}
          </p>
        </button>
        <button v-if="datesAsStrings" type="button" class="delete-button" aria-label="Supprimer les dates" @click="deleteDates">
          <Plus :size="18" color="var(--yb-color-black)" style="rotate:45deg;" />
        </button>
      </div>
      <div v-if="openCalendarPopover" ref="calendarPopover" class="popover">
        <TheCalendar :columns="2" :rows="1" />
      </div>
    </div>
    <div class="search-filter">
      <button type="button" class="search-action" @click="fetchProductsList(filters); openCalendarPopover = false">
        {{ t('search.button') }}
      </button>
    </div>
  </section>
  <TheMobileFilters v-else />
</template>

<style lang="css" scoped>
section {
  display: grid;
  grid-template-columns: repeat(4, auto);
  /* gap: var(--yb-spacing-xs); */
  place-items: center;
  width: fit-content;
  padding: var(--yb-spacing-xs);
  border: 1px solid var(--yb-color-mediumgray);
  border-radius: var(--yb-radius-full);

  @media screen and (width < 1024px) {
    display: none;
  }
  .search-filter {
    position: relative;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 2px;

    &:first-child {
      border-top-left-radius: var(--yb-radius-full);
      border-bottom-left-radius: var(--yb-radius-full);
    }

    &:last-child {
      border-top-right-radius: var(--yb-radius-full);
      border-bottom-right-radius: var(--yb-radius-full);
    }

    &:not(:nth-last-child(-n + 2)) {
      border-right: 1px solid var(--yb-color-mediumgray);
    }

    .popover {
      position: absolute;
      left: 0;
      width: fit-content;
      top: 3.25rem;
      background-color: var(--yb-color-white);
      border-radius: var(--yb-radius-sm);
      box-shadow: var(--yb-color-mediumgray) 0px 4px 8px 0px;
      z-index: 20;
    }

    .search-buttons {
      display: flex;
      align-items: center;
      /* gap: var(--yb-spacing-sm); */

      .delete-button {
        appearance: none;
        display: grid;
        place-items: center;
        padding: 5px;
        cursor: pointer;
        border: none;
        outline: none;
        background-color: transparent;
        border-radius: var(--yb-radius-sm);

        &:hover {
          /*background-color: var(--search-highlight-background-color);*/
          background-color: var(--yb-color-lightgray);
        }
      }
    }

    button.filter-wrapper {
      background-color: var(--yb-color-white);
      border: none;
      font-size: var(--yb-text-base);
      color: var(--yb-color-black);
      min-width: 180px;
    }

    .filter-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      gap: var(--yb-spacing-xs);
      height: 44px;
      padding-left: var(--yb-spacing-sm);
      border-radius: var(--yb-radius-full);

      &.opened {
        /*background-color: var(--search-highlight-background-color);*/
        background-color: var(--yb-color-lightgray);
      }

      &:hover {
        /*background-color: var(--search-highlight-background-color);*/
        background-color: var(--yb-color-lightgray);
      }

      &:focus-visible {
        /*background-color: var(--search-highlight-background-color);*/
        background-color: var(--yb-color-lightgray);
        outline: none;
        border: none;
      }

      .search_query {
        max-width: 135px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .search_query,
      p.active {
        font-weight: var(--yb-text-bold);
      }

      .search_query::placeholder {
        font-weight: var(--yb-text-light);
      }

      input {
        background-color: transparent;
        outline: none;
        border: none;
        color: var(--yb-color-black);
        font-size: var(--yb-text-base);

        &::placeholder {
          color: var(--yb-color-black);
        }
      }

      p {
        margin-right: auto;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      p + * {
        margin-left: auto;
      }
    }

    .search-action {
      border-radius: var(--yb-radius-full);
      background-color: var(--search-action-background-color);
      color: var(--search-action-text-color);
      padding: 0.8rem var(--yb-spacing-xl);
      border: 2px solid var(--search-action-background-color);
      margin-left: 4px;
      font-size: var(--yb-text-base);
      font-weight: var(--yb-text-bold);
      cursor: pointer;

      &:hover {
        background-color: var(--yb-color-dark-primary);
        border: 2px solid var(--yb-color-dark-primary);
      }
    }
  }
}
</style>
