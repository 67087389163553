<script setup lang='ts'>
import { useI18n } from 'vue-i18n';

import useModeListData from '@/composables/useModeListData';
import useModeListFilters from '@/composables/useModeListFilters';

import TheDurationFilter from './TheDurationFilter.vue';
import TheMultiselectFilter from './TheMultiselectFilter.vue';
import ThePriceFilter from './ThePriceFilter.vue';
import TheTimeRangeFilter from './TheTimeRangeFilter.vue';

const { t } = useI18n();

const { places } = useModeListData();
const { addPlacesFilters } = useModeListFilters();
const timeRangeOptions = [
  { label: t('timeRange.morning'), minTime: 480, maxTime: 720, count: 0 },
  { label: t('timeRange.afternoon'), minTime: 720, maxTime: 1020, count: 0 },
  { label: t('timeRange.evening'), minTime: 1020, maxTime: 1440, count: 0 },
];
</script>

<template>
  <div class="filter">
    <h3>{{ t('menu.price') }}</h3>
    <div>
      <ThePriceFilter :placeholder="t('menu.duration')" :min="0" :max="1500" :step="10" :average="79" />
    </div>
  </div>
  <hr>
  <div class="filter">
    <h3>{{ t('menu.duration') }}</h3>
    <TheDurationFilter :min="0" :max="24" :step="1" />
  </div>
  <hr>
  <div class="filter">
    <h3>{{ t('menu.moment') }}</h3>
    <TheTimeRangeFilter :options="timeRangeOptions" />
  </div>
  <hr>
  <div v-if="places.length" class="filter">
    <h3>{{ t('menu.place') }}</h3>
    <TheMultiselectFilter :options="places" @update:model-value="addPlacesFilters" />
  </div>
</template>

<style lang="css" scoped>
h3 {
  margin-bottom: var(--yb-spacing-sm);
}

hr {
  margin-block: var(--yb-spacing-base);
  border: 1px solid var(--yb-color-lightgray);
}
</style>
