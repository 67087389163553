<script setup lang="ts">
import { Search, SlidersHorizontal } from 'lucide-vue-next';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useLocale from '@/composables/useLocale';
import useModeListData from '@/composables/useModeListData';
import useModeListFilters from '@/composables/useModeListFilters';
import useRouting from '@/composables/useRouting.js';

import MobileProductFilters from '../mode-list-filters/MobileProductFilters.vue';
import MobileSearchFilters from '../mode-list-filters/MobileSearchFilters.vue';
import TheMobileSlider from './TheMobileSlider.vue';

const { t } = useI18n();
const { currLocale } = useLocale();
const { datesAsStrings, filters, addSearchingFilter, saveFiltersToSessionStorage } = useModeListFilters();
const { woodyConfig, fetchProductsList, themes } = useModeListData();
const { defaultRoute, redirectOrFetch } = useRouting();
const isSearchOpen = ref(false);
const isFiltersOpen = ref(false);
const hasSearchFilters = ref(true);

const activeTheme = computed(() => filters.value.selections.find(it => it.filterProperties.type === 'taxonomyThemes')?.selection?.[0]) ?? undefined;
const activeThemeLabel = computed(() => {
  const theme = themes.value.find(it => it.id === activeTheme.value);
  return theme?.translatedName.find((it: any) => it.lang === currLocale.value)?.value ?? theme?.name;
});

function handleSearchFilters() {
  isSearchOpen.value = false;
  saveFiltersToSessionStorage();

  redirectOrFetch();

  const anchorOnGridComponent = document.querySelector('yb-list');
  if (anchorOnGridComponent && window.innerWidth < 1024)
    anchorOnGridComponent.scrollIntoView({ behavior: 'smooth' });
}

function handleProductsFilter() {
  isFiltersOpen.value = false;
  saveFiltersToSessionStorage();
  if (woodyConfig.value && window.location.href === woodyConfig.value.homeUrl) {
    if (defaultRoute.value) {
      window.location.href = defaultRoute.value.url;
    }
  }
  else {
    fetchProductsList(filters.value);
  }
  const anchorOnGridComponent = document.querySelector('yb-list');
  if (anchorOnGridComponent && window.innerWidth < 1024)
    anchorOnGridComponent.scrollIntoView({ behavior: 'smooth' });
  // todo : si homeURL renvoyer sur defaultRoute
}

function resetSearchFilters() {
  isSearchOpen.value = false;
  addSearchingFilter('');
  const datesFilter = filters.value.filters.findIndex(it => it.filterProperties.type === 'date_range');
  if (datesFilter && datesFilter > -1) {
    datesAsStrings.value = null;
    filters.value.filters.splice(datesFilter, 1);
  }
  filters.value.selections = filters.value.selections.filter(it => it.filterProperties?.type !== 'taxonomyThemes');
  filters.value.selections = filters.value.selections.filter(it => it.filterProperties?.type !== 'taxonomyTypes');
  filters.value.metrics = filters.value.metrics.filter(it => it.type !== 'crossedTaxonomyCount');
  saveFiltersToSessionStorage();
  fetchProductsList(filters.value);
}

function resetProductFilters() {
  isFiltersOpen.value = false;
  // detele PriceRange filter
  filters.value.filters = filters.value.filters.filter(it => it.filterProperties.type !== 'priceFrom');
  filters.value.filters = filters.value.selections.filter(it => it.filterProperties.type !== 'priceFrom');
  // delete Duration filter
  filters.value.filters = filters.value.filters.filter(it => it.filterProperties.type !== 'sessionDuration');
  filters.value.filters = filters.value.selections.filter(it => it.filterProperties.type !== 'sessionDuration');
  // delete TimeRange filter
  filters.value.filters = filters.value.filters.filter(it => it.filterProperties.type !== 'timeRange');
  filters.value.filters = filters.value.selections.filter(it => it.filterProperties.type !== 'timeRange');
  // delete Places filter
  filters.value.selections = filters.value.selections.filter(it => it.filterProperties?.type !== 'taxonomyPlaces');
  saveFiltersToSessionStorage();
  fetchProductsList(filters.value);
}
</script>

<template>
  <div class="mobile-filters-wrapper">
    <button
      :aria-haspopup="true"
      aria-controls="search-dialog"
      class="search-button"
      @click="isSearchOpen = !isSearchOpen"
    >
      <div class="search-button-content">
        <Search :size="22" color="var(--yb-color-black)" aria-hidden="true" />
        <div v-if="hasSearchFilters" class="search-button-results">
          <p class="theme">
            {{ activeThemeLabel ?? t('search.default') }}
          </p>
          <p class="dates">
            {{ datesAsStrings ?? t('search.dates') }}
          </p>
        </div>
        <p v-else>
          {{ t('search.button') }}
        </p>
      </div>
    </button>
    <button
      :aria-haspopup="true"
      aria-controls="filters-dialog"
      class="filters-button"
      @click="isFiltersOpen = !isFiltersOpen"
    >
      <SlidersHorizontal :size="22" color="var(--yb-color-black)" aria-hidden="true" />
    </button>
  </div>
  <TheMobileSlider :is-open="isSearchOpen" :title="t('search.label')" anchor="top" :footer="true" @validation="handleSearchFilters" @close="isSearchOpen = false" @reset="resetSearchFilters">
    <MobileSearchFilters />
  </TheMobileSlider>
  <TheMobileSlider :is-open="isFiltersOpen" :title="t('menu.filters')" anchor="top" :footer="true" @close="isFiltersOpen = false" @validation="handleProductsFilter" @reset="resetProductFilters">
    <MobileProductFilters />
  </TheMobileSlider>
</template>

<style lang="css" scoped>
.mobile-filters-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw - var(--yb-spacing-sm));
  gap: var(--yb-spacing-sm);

  .search-button,
  .filters-button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--yb-color-white);
    color: var(--yb-color-black);
    border: 1px solid var(--yb-color-mediumgray);
    border-radius: var(--yb-radius-sm);
    height: 54px;
  }

  .filters-button {
    aspect-ratio: 1;
  }

  .search-button {
    flex-grow: 1;
    justify-content: flex-start;
    padding-left: var(--yb-spacing-sm);
  }

  .search-button-content {
    display: flex;
    align-items: center;
    gap: var(--yb-spacing-xs);
    color: var(--yb-color-black);

    .search-button-results {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .theme {
        font-weight: var(--yb-text-bold);
        font-size: var(--yb-text-base);
      }

      .dates {
        font-weight: var(--yb-text-light);
        font-size: var(--yb-text-sm);
      }
    }
  }
}
</style>
