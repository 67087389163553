<!-- MyComponent.vue -->
<script setup lang="ts">
// @ts-expect-error error on import path
import { DatePicker } from 'v-calendar';
import { computed, ref } from 'vue';
import 'v-calendar/style.css';

import useLocale from '@/composables/useLocale';
import useModeListData from '@/composables/useModeListData.js';
import useModeListFilters from '@/composables/useModeListFilters';
import useRouting from '@/composables/useRouting.js';

const props = withDefaults(defineProps<{
  rows?: number;
  columns?: number;
}>(), {
  rows: 1,
  columns: 2,
});

const { addDatesFilter, filters } = useModeListFilters();
const { currLocale } = useLocale();
const { woodyConfig } = useModeListData();
const { defaultRoute } = useRouting();

const savedDates = computed(() => filters.value.filters.find(it => it.filterProperties.type === 'date_range')?.selection);
const existingRange = computed(() => savedDates.value ? { start: savedDates.value.startDate, end: savedDates.value.endDate } : null);
const datesRange = ref(existingRange);

const anchorOnGridComponent = document.querySelector('yb-list');

function handleDatesSelection(dates: { start: number; end: number }) {
  addDatesFilter(dates);
  if (woodyConfig.value?.homeUrl && window.location.href === woodyConfig.value.homeUrl) {
    if (defaultRoute.value)
      window.location.href = defaultRoute.value.url;
  }
  if (anchorOnGridComponent && window.innerWidth >= 1024)
    anchorOnGridComponent.scrollIntoView({ behavior: 'smooth' });
}
</script>

<template>
  <div>
    <DatePicker v-model.range.number="datesRange" range mode="date" :columns="props.columns" :rows="props.rows" :min-date="new Date()" :locale="currLocale" expanded @update:model-value="(v: any) => handleDatesSelection(v)" />
  </div>
</template>

<style lang="css">
.vc-light.vc-attr,
.vc-light .vc-attr {
  --vc-highlight-outline-bg: var(--yb-calendar-highlight) !important;
  --vc-highlight-outline-border: var(--yb-calendar-primary) !important;
  --vc-highlight-outline-content-color: var(--yb-calandar-text-highlight) !important;

  --vc-highlight-light-bg: var(--yb-calendar-highlight) !important;
  --vc-highlight-light-content-color: var(--yb-calandar-text-highlight) !important;
  --vc-highlight-solid-bg: var(--yb-calendar-primary) !important;
  --vc-highlight-solid-content-color: var(--yb-calendar-text-selected) !important;
}
.vc-focus:focus-within {
  box-shadow: 0 0 0 2px var(--yb-calendar-highlight) !important;
}
.vc-title,
.vc-arrow {
  background-color: transparent !important;
}
.vc-title {
  font-size: var(--yb-text-base) !important;
  font-weight: var(--yb-text-bold) !important;
}
</style>
