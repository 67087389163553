<script setup lang='ts'>
import { computed, ref } from 'vue';

import useModeListData from '@/composables/useModeListData';
import useModeListFilters from '@/composables/useModeListFilters';

type Option = { label: string; minTime: number; maxTime: number; count: number };

const props = defineProps<{
  options: Array<Option>;
}>();

const { filters, saveFiltersToSessionStorage } = useModeListFilters();
const { timeRanges, fetchProductsList } = useModeListData();
const selectedOptions = ref<Array<Option>>([]);
const debounce = ref();
const options = computed<Array<Option>>(() => {
  return timeRanges.value.map((range) => {
    const defaultOption = props.options.find(option => option.minTime === range.minTime && option.maxTime === range.maxTime);
    if (defaultOption)
      range.label = defaultOption.label;
    return range;
  });
});

const savedTimeRange = filters.value.filters.filter(it => it.filterProperties.type === 'timeRange');
if (savedTimeRange.length > 0) {
  for (const range of savedTimeRange[0].selection) {
    const matchingOption = props.options.find(it => it.maxTime === range.maxTime && it.minTime === range.minTime);
    if (matchingOption) {
      const isSelected = selectedOptions.value.find(it => it.label === matchingOption.label);
      if (!isSelected)
        selectedOptions.value.push(matchingOption);
    }
  }
}

function setupTimeRangeFilter() {
  const timeRanges = [];
  for (const option of selectedOptions.value) {
    timeRanges.push({ minTime: option.minTime, maxTime: option.maxTime });
  }
  const existingTmeRangeFilterIndex = filters.value.filters.findIndex(it => it.filterProperties.type === 'timeRange');
  if (existingTmeRangeFilterIndex > -1) {
    filters.value.filters.splice(existingTmeRangeFilterIndex, 1);
  }
  if (timeRanges.length) {
    filters.value.filters.push({
      filterProperties: { type: 'timeRange' },
      selection: timeRanges,
    });
  }
  saveFiltersToSessionStorage();
  fetchProductsList(filters.value);
}

function selectOption(option: Option) {
  if (option.count === 0)
    return;
  if (debounce.value)
    clearTimeout(debounce.value);
  if (selectedOptions.value.some(it => it.label === option.label)) {
    selectedOptions.value = selectedOptions.value.filter(item => item.label !== option.label);
  }
  else {
    selectedOptions.value.push(option);
  }
  debounce.value = setTimeout(() => setupTimeRangeFilter(), 100);
}
</script>

<template>
  <ul id="options-list" role="listbox" class="options-list">
    <div
      v-for="(option, index) in options"
      :key="index"
      class="option-row"
      tabindex="0"
    >
      <li
        role="option"
        tabindex="0"
        class="option"
        :aria-selected="selectedOptions.some(it => it.label === option.label)"
        @click="selectOption(option)"
        @keydown="selectOption(option)"
      >
        <label :id="index.toString()" :for="index.toString()" :class="option.count === 0 ? 'disabled' : ''">{{ option.label }}<span style="margin-left: 4px;">({{ option.count }})</span></label>
        <input :id="index.toString()" type="checkbox" :name="option.label" :value="option" :checked="selectedOptions.some(it => it.label === option.label)" :aria-describedby="index.toString()" :disabled="option.count === 0">
      </li>
    </div>
  </ul>
</template>

<style lang="css" scoped>
ul {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

.options-list {
  list-style-type: none;
  background-color: var(--yb-color-white);
  border-radius: var(--yb-radius-sm);
}

.option-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: var(--yb-radius-sm);
  outline: none;
  padding-block: var(--yb-spacing-xs);
  padding-right: var(--yb-spacing-xs);
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-wrap: nowrap;
  color: var(--yb-color-black);
  cursor: pointer;

  & > * {
    pointer-events: none;
  }

  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    width: 16px;
    aspect-ratio: 1;
    border: 1px solid var(--yb-color-mediumgray);
    border-radius: 2px;
    background-color: var(--yb-color-white);
    margin-inline: var(--yb-spacing-xs);
    accent-color: var(--yb-color-primary);

    &:disabled {
      background-color: var(--yb-color-lightgray);
    }
  }

  .disabled {
    color: var(--yb-color-lightgray);
  }

  input[type='checkbox']:checked {
    background-color: var(--yb-color-primary);
    border-color: var(--yb-color-primary);
    color: var(--yb-color-white);
  }

  input[type='checkbox']:checked::before {
    content: '\2713';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    text-align: center;
    color: var(--yb-color-white);
    font-size: 16px;
  }
}

.option-row:hover,
.option-row:focus,
.option-row:focus-visible {
  background-color: var(--yb-color-lightgray);
}
</style>
