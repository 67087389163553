<script setup lang='ts'>
import { onMounted, onUnmounted, ref } from 'vue';

import useLocale from '@/composables/useLocale';
import useModeListData from '@/composables/useModeListData';
import useModeListFilters from '@/composables/useModeListFilters';

const data = ref([
  { label: 'Visiter Marseille', count: 6 },
  { label: 'Mer et calanques', count: 4 },
  { label: 'Activités plein air', count: 6 },
  { label: 'Gastronomie et traditions', count: 6 },
  { label: 'En provence', count: 6 },
  { label: 'OM', count: 6 },
]);
const { themes, fetchProductsList, themesRouting } = useModeListData();
const { filters, saveFiltersToSessionStorage } = useModeListFilters();
const { currLocale } = useLocale();
const keyboardIndex = ref(-1);

function getTranslatedName(theme: any) {
  return theme.translatedName.find((it: any) => it.lang === currLocale.value)?.value ?? theme.name;
}

function setTheme(theme: typeof themes.value[number]) {
  const activeTheme = filters.value.selections.find(it => it.filterProperties.type === 'taxonomyThemes');
  const existingTypesIndex = filters.value.selections.findIndex(it => it.filterProperties.type === 'taxonomyTypes');
  const crossedTaxonomies = filters.value.metrics.find(it => it.type === 'crossedTaxonomyCount');

  if (activeTheme) {
    activeTheme.selection = [theme.id];
  }
  else {
    filters.value.selections.push({
      filterProperties: {
        type: 'taxonomyThemes',
      },
      selection: [theme.id],
    });
  }
  // On reset les types sur un switch de theme
  if (existingTypesIndex > -1) {
    filters.value.selections.splice(existingTypesIndex, 1);
  }

  if (crossedTaxonomies) {
    crossedTaxonomies.crossedTaxonomies.themes = [theme.id];
  }
  else {
    filters.value.metrics.push({
      type: 'crossedTaxonomyCount',
      taxonomyTypes: ['TYPES'],
      crossedTaxonomies: {
        themes: [theme.id],
      },
    });
  }
  // Reset du search
  filters.value.sorts = filters.value.sorts.filter((it: any) => it.type !== 'search');
  saveFiltersToSessionStorage();

  const defaultRoute = themesRouting.value.find(it => it.taxonomies?.length === 0);
  const selectedThemes = filters.value.selections.find((it: any) => it.filterProperties.type === 'taxonomyThemes')?.selection ?? [];
  const selectedTypes = filters.value.selections.find((it: any) => it.filterProperties.type === 'taxonomyTypes')?.selection ?? [];
  const routingConditions: any = [];
  if (selectedThemes.length > 0)
    routingConditions.push({ type: 'THEMES', value: selectedThemes });
  if (selectedTypes.length > 0)
    routingConditions.push({ type: 'TYPES', value: selectedTypes });
  const specificRoute = themesRouting.value.find(route => JSON.stringify(route.taxonomies) === JSON.stringify(routingConditions));

  if (specificRoute && specificRoute.url !== window.location.href) {
    window.location.href = specificRoute.url;
  }
  else if (!specificRoute && defaultRoute && window.location.href !== defaultRoute.url) {
    window.location.href = defaultRoute.url;
  }
  else {
    fetchProductsList(filters.value);
  }
}

function handleKeyboardNavigation(event: KeyboardEvent) {
  event.preventDefault();
  const themes = document.querySelector('yb-search')?.shadowRoot?.querySelectorAll('.theme');
  if (event.key === 'ArrowDown') {
    keyboardIndex.value = keyboardIndex.value + 1 === themes?.length ? 0 : keyboardIndex.value + 1;
  }
  else if (event.key === 'ArrowUp') {
    keyboardIndex.value = keyboardIndex.value - 1 < 0 ? (themes?.length ?? data.value.length) - 1 : keyboardIndex.value - 1;
  }
  // @ts-expect-error focus() is not available [indexed access?]
  themes?.[keyboardIndex.value].focus();
}

onMounted(() => {
  document.addEventListener('keydown', handleKeyboardNavigation);
});
onUnmounted(() => {
  document.removeEventListener('keydown', handleKeyboardNavigation);
});
</script>

<template>
  <div class="themes">
    <button v-for="(item, index) in themes" :key="index" class="theme" tabindex="0" @click="setTheme(item)" @keydown.enter="setTheme(item)">
      {{ getTranslatedName(item) }}
      <span v-if="item.count" class="count">{{ item.count }}</span>
    </button>
  </div>
</template>

<style lang="css" scoped>
.themes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: fit-content;
  grid-auto-flow: row;
  gap: var(--yb-spacing-xs);
  padding: 16px 10px 16px 16px;
  font-size: var(--yb-text-base);

  .theme {
    display: flex;
    align-items: center;
    min-width: 250px;
    white-space: nowrap;
    color: var(--yb-color-black);
    padding: var(--yb-spacing-xs) var(--yb-spacing-xs) var(--yb-spacing-xs) var(--yb-spacing-sm);
    background-color: var(--yb-color-white);
    outline-color: var(--yb-color-primary);
    border: 1px solid var(--yb-color-mediumgray);
    border-radius: var(--yb-radius-sm);
    cursor: pointer;

    &:hover {
      background-color: var(--search-highlight-background-color);
      border: 1px solid var(--search-highlight-background-color);
    }
  }

  .count {
    font-size: var(--yb-text-xs);
    font-weight: var(--yb-text-light);
    color: var(--yb-color-white);
    background-color: var(--yb-color-black);
    margin-left: auto;
    border-radius: 7px;
    padding-inline: 6px;
    padding-block: 3px;
  }
}
</style>
