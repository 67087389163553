<script setup lang='ts'>
import { CalendarDays, Search } from 'lucide-vue-next';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useLocale from '@/composables/useLocale';
import useModeListData from '@/composables/useModeListData';
import useModeListFilters from '@/composables/useModeListFilters';

import TheCalendar from '../mode-list/TheCalendar.vue';

const { t } = useI18n();
const { filters, addSearchingFilter, saveFiltersToSessionStorage } = useModeListFilters();
const { themes, fetchProductsList } = useModeListData();
const { currLocale } = useLocale();

const activeTheme = computed(() => filters.value.selections.find(it => it.filterProperties.type === 'taxonomyThemes')?.selection?.[0]) ?? undefined;
const activeThemeObject = computed(() => themes.value.find(it => it.id === activeTheme.value));

const currentSearch = computed(() => filters.value.sorts.find(it => it.type === 'search')?.query ?? '');
function handleSearchInput(event: any) {
  addSearchingFilter(event.target.value);
}

function getTranslatedName(theme: any) {
  return theme.translatedName.find((it: any) => it.lang === currLocale.value)?.value ?? theme.name;
}

function setTheme(theme: typeof themes.value[number]) {
  const activeTheme = filters.value.selections.find(it => it.filterProperties.type === 'taxonomyThemes');
  const crossedTaxonomies = filters.value.metrics.find(it => it.type === 'crossedTaxonomyCount');
  filters.value.selections = filters.value.selections.filter(it => it.filterProperties.type !== 'taxonomyTypes');

  if (activeTheme) {
    activeTheme.selection = [theme.id];
  }
  else {
    filters.value.selections.push({
      filterProperties: {
        type: 'taxonomyThemes',
      },
      selection: [theme.id],
    });
  }

  if (crossedTaxonomies) {
    crossedTaxonomies.crossedTaxonomies.themes = [theme.id];
  }
  else {
    filters.value.metrics.push({
      type: 'crossedTaxonomyCount',
      taxonomyTypes: ['TYPES'],
      crossedTaxonomies: {
        themes: [theme.id],
      },
    });
  }
  saveFiltersToSessionStorage();
  fetchProductsList(filters.value);
}
</script>

<template>
  <div class="search-filter">
    <div class="filter-wrapper" tabindex="0">
      <Search :size="18" color="var(--yb-color-black)" aria-hidden="true" />
      <input type="text" :placeholder="t('search.button')" :aria-label="t('search.button')" :value="currentSearch" @change="(v) => handleSearchInput(v)">
    </div>
  </div>
  <div class="themes">
    <button v-for="(item, index) in themes" :key="index" class="theme" :class="{ selected: activeThemeObject?.id === item.id }" tabindex="0" @click="setTheme(item)" @keydown.enter="setTheme(item)">
      {{ getTranslatedName(item) }}
      <span v-if="item.count" class="count">{{ item.count }}</span>
    </button>
  </div>
  <h3 class="dates-title">
    <span><CalendarDays :size="22" color="var(--yb-color-black)" aria-hidden="true" /></span>{{ t('search.dates') }}
  </h3>
  <TheCalendar :columns="1" :rows="2" />
</template>

<style lang="css" scoped>
.dates-title {
  display: flex;
  align-items: center;
  margin-bottom: var(--yb-spacing-sm);
  margin-top: var(--yb-spacing-base);

  span {
    margin-right: var(--yb-spacing-sm);
  }
}

.search-filter {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .filter-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: var(--yb-spacing-xs);
    padding: var(--yb-spacing-sm);
    border: 1px solid var(--yb-color-mediumgray);
    border-radius: var(--yb-radius-sm);
    margin-bottom: var(--yb-spacing-sm);

    input {
      background-color: transparent;
      outline: none;
      border: none;
      color: var(--yb-color-black);
      font-size: var(--yb-text-base);
      caret-color: var(--yb-color-primary);

      &::placeholder {
        color: var(--yb-color-black);
      }
    }
  }
}

.theme {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  color: var(--yb-color-black);
  padding: var(--yb-spacing-xs) var(--yb-spacing-sm);
  background-color: var(--yb-color-white);
  border: 1px solid var(--yb-color-lightgray);
  outline-color: var(--yb-color-primary);
  border-radius: var(--yb-radius-sm);
  margin-bottom: var(--yb-spacing-xs);

  &.selected {
    border: 1px solid var(--yb-color-darkgray);
    background-color: var(--search-highlight-background-color);
  }
}

.count {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--yb-text-xs);
  font-weight: var(--yb-text-light);
  background-color: var(--yb-color-black);
  color: var(--yb-color-white);
  aspect-ratio: 1;
  padding-inline: 4px;
  margin-left: auto;
  border-radius: 4px;
}
</style>
