import { computed } from 'vue';

import useModeListData from '@/composables/useModeListData.js';
import useModeListFilters from '@/composables/useModeListFilters.js';

const { themesRouting, fetchProductsList } = useModeListData();
const { filters } = useModeListFilters();

const defaultRoute = computed(() => themesRouting.value.find(it => it.taxonomies?.length === 0));

export default function useRouting() {
  async function redirectOrFetch() {
    const selectedThemes = filters.value.selections.find((it: any) => it.filterProperties.type === 'taxonomyThemes')?.selection ?? [];
    const selectedTypes = filters.value.selections.find((it: any) => it.filterProperties.type === 'taxonomyTypes')?.selection ?? [];
    const routingConditions: any = [];
    if (selectedThemes.length > 0)
      routingConditions.push({ type: 'THEMES', value: selectedThemes });
    if (selectedTypes.length > 0)
      routingConditions.push({ type: 'TYPES', value: selectedTypes });

    const specificRoute = themesRouting.value.find(route =>
      JSON.stringify(route.taxonomies) === JSON.stringify(routingConditions) || JSON.stringify(route.taxonomies) === JSON.stringify(routingConditions.reverse()),
    );

    if (specificRoute && specificRoute.url !== window.location.href) {
      window.location.href = specificRoute.url;
    }
    else if (!specificRoute && defaultRoute.value && window.location.href !== defaultRoute.value.url) {
      window.location.href = defaultRoute.value.url;
    }
    else {
      fetchProductsList(filters.value);
    }
  }

  return {
    defaultRoute,
    redirectOrFetch,
  };
}
